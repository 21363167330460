import React from 'react';
import Container from '@material-ui/core/Container';
import Header from './components/Header';

function App() {
  return (
    <Container maxWidth="xl">
      <Header />
    </Container>
  );
}

export default App;
